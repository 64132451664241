/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DKLabel,
  DKIcon,
  DKButton,
  DKIcons,
  DKSpinner,
  DKFilterPopup,
  DKListPicker2,
  DKSegmentControl,
  DKPaginator,
  INPUT_TYPE,
  Toggle,
  showToast,
  TOAST_TYPE,
  DKTooltipWrapper,
  DKDateRangePicker
} from 'deskera-ui-library';
import ChartHolder from './ChartHolder';
import ReportService from '../../services/reports/Report';
import { COLUMN_LAYOUT, CUSTOM_FIELD_TYPE, DATE_FORMATS, FILTER_DATA_TYPE, FILTER_LOGICAL_OPERATORS, SORT_OPTIONS } from '../../constants/Enum';
import DataSourcePopup from './DataSourcePopup';
import {
  AI_REPORT_TABLE_CONSTANT,
  ALLOW_PEOPLE_REPORT_FILTER_COLUMN,
  APP_NAME,
  LIB_TO_REPORT_OP_CONV,
  MAX_WIDGET_DATA_PAGE_SIZE,
  REPORT_TO_LIB_OP_CONV,
  TABLES_TO_REPLACE_DROPDOWN_FIELD_CONFIG
} from '../../constants/Constant';
import { getTableByName, getTablesByAppName } from '../../store/slices/tableSlice';
import { store } from '../../store/store';
import { deepClone, deepCloneObject, extraQueryFiltersAsContextMsg, getDefaultDateForDateRange, isDate, isEmpty, isEmptyObject, modifyPeopleColumn, modifyPeopleOtherReportColumn, parseFilters, reverseTimezoneOffset } from '../../utilities/Common';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import ic_drag from '../../assets/ic_drag.png'
import ic_expand from '../../assets/ic_expand_arrow.png'
import ic_collapsed from '../../assets/ic_collapsed_arrow.png'
import { IColumnShortData, IWidget, IWidgetSortBy } from '../../models/Report';
import { useAppSelector } from '../../store/hooks';
import { isViewportMobile } from '../../utilities/ViewportSize';
import ApiConstants from '../../constants/ApiConstant';
import { getDateFromString, getDateStrFromDate, getFormattedDateString } from '../../utilities/Date';
import { getMultiTenantAccess } from '../../store/slices/tenantSlice';

export interface IWidgetProps {
  widgetIndex: number;
  widget: IWidget;
  key: string;
  columnLayout: COLUMN_LAYOUT;
  onDataSourceSave: (
    widget: IWidget,
    preventRefresh?: boolean,
    preventAutoSave?: boolean,
    needReportSave?: boolean
  ) => void;
  onFilter: (data: any, operator?: FILTER_LOGICAL_OPERATORS) => void;
  onSortApply: (data: IWidgetSortBy) => void;
  onSortEnd: (oldIndex: any, newIndex: any) => void;
  onFilterDateApplied?: (data: any, newIndex:any) => void;
  onDeleteWidget: (index: number) => void;
  onCopyWidget: (widgetName: string, index: number) => void;
  toggleWidgetExpandedState: (isExpanded: boolean, widgetIndex: number) => void;
  onToggleGlobalLocalReport?: (data:any,widgetIndex: number) => void;
  isSystemReport?: boolean
  aiReport?: any;
  filterChangeCount?: any;
}

function Widget({
  widgetIndex,
  widget,
  key,
  columnLayout,
  onDataSourceSave,
  onFilter,
  onSortApply,
  onSortEnd,
  onDeleteWidget,
  onCopyWidget,
  toggleWidgetExpandedState,
  isSystemReport,
  aiReport,
  onFilterDateApplied,
  onToggleGlobalLocalReport
}: IWidgetProps) {
  const [loading, setLoading] = useState(false);
  const [enableNameEdit, setEnableNameEdit] = useState(false);
  const [inputData, setInputData] = useState(null);
  const [inputAdditionalData, setInputAdditionalData] = useState(null);
  const [showDataSourcePicker, setShowDataSourcePicker] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const filterRef = useRef<any>(null);
  const widgetNameInputRef = useRef<any>(null);
  const [reportHeaderState, setReportHeaderState] = useState<any>(
    deepCloneObject({
      showCalendar: false,
      toggle: false,
      hasCustomField: false,
      hasMapping: false,
      hideToggle: true,
      searchBar: { hidden: true },
      date: {
        startDate: getDefaultDateForDateRange('startDate',widget),
        endDate: getDefaultDateForDateRange('endDate',widget),
        isHidden: false,
        dataOptions: [],
        currentValue: null
      },
      isMultiTenantData: widget.isMultiTenantData,
      filterChangeCount: widget.filterChangeCount
    })
  );
  const hasMultiTenantAccess = useAppSelector(getMultiTenantAccess());
  const allTables = useAppSelector(getTablesByAppName(widget.generatedFrom === 'books' ? APP_NAME.ERP : widget.generatedFrom));
  const peopleEmployeeFieldsData = useAppSelector((state) => {
    return state.records.peopleEmployeeFields;
  });
  const peopleUsers = useAppSelector((state) => {
    return state.tenant.peopleUsers
  })
  // useOnClickOutside(filterRef, () => setShowFilterPopup(false));

  const getAppName = () => {
    if (widget.appName) return widget.appName;

    const tableData = getTableByName(widget.tableName)(store.getState());
    return tableData?.appName || APP_NAME.ALL;
  };
  const getSortData = () => {
    let sortBy = null;
    if (!isEmpty(widget.sortBy?.column) && !isEmpty(widget.sortBy?.table)) {
      sortBy = { ...widget.sortBy, direction: widget.sortBy?.direction === 'DEFAULT' ? SORT_OPTIONS.DEFAULT : widget.sortBy?.direction };
    }
    return sortBy;
  }
  const getDefaultAdditionalFilter = (result = []) => {
    let additionalFilters: any = allTables?.find(table => table.tableName === widget.tableName)?.additionalFilters || [];
    if (!isEmptyObject(result)) {
      additionalFilters = result
    }
    additionalFilters = additionalFilters.map((filter: any,index:any) => {
      return {
          index,
          condition: REPORT_TO_LIB_OP_CONV[filter.condition],
          tableColumn: filter.tableColumn,
          code: filter.tableColumn,
          tableName: filter.tableName,
          dataType: getDataType(filter).toUpperCase(),
          valuesArray: filter.valuesArray || [],
          customField: false,
        value: filter.dataType === 'STRING' ? '' : filter?.valuesArray[isSystemReport ? filter?.valuesArray?.length - 1 : 0]?.key
      }
    })
    return additionalFilters;
  }
  const getParam = (widget:any,additionalFilter:any, isExport:any) => {
    let param = {}
    switch(widget.tableName) {
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_TYPE:
        param = {
          content: 'Give me total number \"employeeTypeCF\" as employeecount and \"pwRegion\" as country and convert country data into full name of country e.g. IN convert to India as country and tenant_id as tenant_id and \"employeeTypeCF\" as employeetype into full name of \"employeeTypeCF\" and group by \"pwRegion\" and \"employeeTypeCF\" and country is not null and deleted false and employeetype is not null.',
          filters: parseFilters(widget.filters)
        }
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_TERMINATION_STATUS:
        param = {
          content: 'Give me total number employee as employeecount and pwRegion as country and convert country data into full name of country e.g. IN convert to India as country and tenant_id as tenant_id and terminationStage as terminationstage group by country and terminationstage and country is not null and terminationstage is not null and deleted false.',
          filters: parseFilters(widget.filters)
        }
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT:
        param = {
          content: 'Give me total number employee as employeecount and pwRegion as country and convert country data into full name of country e.g. IN convert to India as country and tenant_id as tenant_id and departmentCostCenter as department group by country and departmentCostCenter and country is not null and department is not null and deleted false.',
          filters: parseFilters(widget.filters)
        }
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_PROMOTION_STATUS:
        param = {
          content: `Give me total number employee as employeecount and pwRegion as country and convert country data into full name of country e.g. IN convert to India as country and tenant_id as tenant_id and group by country and country is not null and effectiveCompensations is not null and deleted false.`,
          filters: parseFilters(widget.filters)
        }
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_STATUS:
        const {extraFilters,tenantFilter} = extraQueryFiltersAsContextMsg(widget);
        param = {
          content: `Give me total number employee as employeecount  and tenant_id and pwRegion as country and convert country data into full name of country e.g. IN convert to India as country and employmentStatus as employmentStatus and employmentStatus in Title case and group by pwRegion and tenant_id and pwRegion is not null and employmentStatus is not null and deleted false ${extraFilters} .`,
          filters: parseFilters(tenantFilter)
        }
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION:
        const terminationDateRange = reportHeaderState?.date?.startDate ? `and terminationDate between ${reportHeaderState.date.startDate} and ${reportHeaderState.date.endDate}`: '';
        param = {
          content: `give me employee firstName and lastName and firstName lastName as fullName and terminationType as terminationType and terminationReason as terminationReason and terminationStage as terminationStage and \"terminationDate\" as terminationDate and employeeId as employeeId and title and terminationStage is 'terminated' and terminationStage value in Title case and tenant_id as tenant_id and deleted false ${terminationDateRange} .`,
          filters: parseFilters(widget.filters)
        }
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS:
        param = {
          status:'active',
          deleted:false,
          filterDate: getReportDateFilterString(reportHeaderState),
          
          filters: parseFilters(widget.filters),
          additionalFilters: !isEmptyObject(additionalFilter) ? additionalFilter : getDefaultAdditionalFilter() ,
          customFieldFilters: widget?.customFieldFilters,
          toDate: getDateStrFromDate(new Date(),DATE_FORMATS['YYYY-MM-DD']),
          logicalOperator: widget.logicalOperator || FILTER_LOGICAL_OPERATORS.AND,
          sortBy: getSortData()
        }
        if(!isExport) {
          param = {
            ...param,
            limit: MAX_WIDGET_DATA_PAGE_SIZE,
            page:widget.paginationData?.currentPage || 1,
          }
        } else {
          const columnsToExport:any = []
          widget?.columns?.forEach((key:any)=>{
            widget.columnConfig?.forEach((config:any)=>{
              if(config?.id?.split("__")[1] === key) {
                const optionsToExport = config.options.map((op:any)=> {
                  if(typeof op.name === 'object') {
                    return op.name
                  } else {
                    return op
                  }
                })
                columnsToExport.push({id: key, name: config.name, type: config.type, options: optionsToExport });
              }
            })
          })
          param = {
            ...param,
            columnsToExport: columnsToExport,
          }
        }
        break;

    }
    return param;
  }
  const executeReportData = async (isExport = false) => {
    if (!widget.tableName || !widget.dataTables?.length) return;
    setLoading(true);
    try {
      widget.dataTables = widget?.dataTables?.filter((table: any) => !isEmpty(table.columns) || !isEmpty(table.customFieldColumns));
      let additionalFilter: any = widget.additionalFilter?.map((filter: any, index) => {
        return ({
          ...filter,
          index,
          condition: REPORT_TO_LIB_OP_CONV[filter.condition],
          key: `${filter.tableColumn}__${filter.tableName || filter.code}`,
          value: filter.dataType === 'DROPDOWN' ? filter?.value?.productId || '' : filter.value || '',
        });
      }) || [];
      let allAdditionFilter: any = allTables?.find(table => table.tableName === widget.tableName)?.additionalFilters || [];
      let result = allAdditionFilter.filter((o1:any) => !additionalFilter.some((o2:any) => o1.tableColumn === o2.tableColumn));
      if (!isEmptyObject(result)) {
        additionalFilter = [...additionalFilter,...getDefaultAdditionalFilter(result)]
      }
      
      let aiReportData:any;
      let reportData: any
      if(aiReport) {
        let param:any = {};
        const isToggleChange = widget.isMultiTenantData !== reportHeaderState.isMultiTenantData
        const isFilterChange = widget.filterChangeCount !== reportHeaderState.filterChangeCount
        widget.isMultiTenantData = reportHeaderState.isMultiTenantData
        widget.filterChangeCount = reportHeaderState.filterChangeCount
        if(!widget.aiReportWidgetId || isToggleChange || isFilterChange) {
          param = getParam(widget,additionalFilter,isExport);
          
          
          if (
            widget.tableName ===
            AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS
          ) {
            aiReportData = await ReportService.getInstance().getAIDefaultReport(
              param,
              {
                isMultiTenantData: widget.isMultiTenantData,
                isExport: isExport
              }
            );
          } else {
            aiReportData =
              await ReportService.getInstance().getAIReportByContent(param, {
                isMultiTenantData: widget.isMultiTenantData,
                isExport: isExport
              });
          }
        } else {
          const param = getParam(widget,additionalFilter,isExport)
          aiReportData = await ReportService.getInstance().getAIReportByMessageId(widget.aiReportWidgetId, param,{
            isMultiTenantData: widget.isMultiTenantData,
            isExport: isExport
          });

        }
        console.log(aiReportData);
      
      if (
        aiReportData?.reply_message?.sqlQuerySaveResponse &&
        aiReportData?.reply_message?.sqlQuerySaveResponse.length > 0 &&
        aiReportData?.reply_message?.sqlQuerySaveResponse[0].rowData
      ) {
        let rowData =
          aiReportData?.reply_message?.sqlQuerySaveResponse[0].rowData;
        const queryId = aiReportData?.reply_message?.sqlQuerySaveResponse[0].id;
        if (queryId && rowData && rowData.length > 0) {
          widget.aiReportWidgetId = queryId;
        }
        let newAIReportData: any = [];
        rowData.forEach((row: any) => {
          let aiReportObj: any = {
            // ...row
          };

          widget.columnConfig?.forEach((col: any) => {
            let keys = col.id.split('__');
            const isColumnSelected = widget.columns.includes(keys[1]);
            if (isColumnSelected) {
              if (row[keys[1]] && typeof row[keys[1]] !== 'boolean') {
                aiReportObj[col.id] = getColumnValue(widget,col,keys,row);
              } else if (row[keys[1]] && typeof row[keys[1]] === 'boolean') {
                aiReportObj[col.id] = col.type === INPUT_TYPE.SELECT ? [row[keys[1]]] : String(row[keys[1]]);
              } else {
                let newKeys;
                if (
                  widget.tableName ===
                  AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS
                ) {
                  newKeys = keys[1].split('_');
                  newKeys = newKeys.join('');
                } else {
                  newKeys = keys[1];
                }

                if (row[newKeys] && typeof row[newKeys] !== 'boolean') {
                  aiReportObj[col.id] = row[newKeys];
                } else if(typeof row[newKeys] === 'boolean') {
                  aiReportObj[col.id] = String(row[newKeys]);
                } else {
                  aiReportObj[col.id] = '';
                } 
              }
              if(isDate(aiReportObj[col.id])) {
                aiReportObj[col.id] = reverseTimezoneOffset(aiReportObj[col.id])
              }
            }
          });
          newAIReportData.push(aiReportObj);
          const totalCount =
            aiReportData?.reply_message?.sqlQuerySaveResponse[0]?.totalCount ||
            newAIReportData.length;
          reportData = {
            additionalFields: [],
            columnTotal: {},
            data: newAIReportData,
            limit: MAX_WIDGET_DATA_PAGE_SIZE,
            page: 1,
            sortBy: null,
            totalCount: totalCount
          };
        });
      } else if (
        isExport &&
        aiReport &&
        aiReportData?.reply_message?.sqlQuerySaveResponse &&
        aiReportData?.reply_message?.sqlQuerySaveResponse.length > 0 &&
        aiReportData?.reply_message?.sqlQuerySaveResponse[0].url
      ) {
        const filePath =
          aiReportData?.reply_message?.sqlQuerySaveResponse[0].url;

        downloadFile(filePath, getFileNameFromURL(filePath));
        return;
      } else {
        //set empty data
        reportData = {
          additionalFields: [],
          columnTotal: {},
          data: [],
          limit: MAX_WIDGET_DATA_PAGE_SIZE,
          page: 1,
          sortBy: null,
          totalCount: 0
        };
      }
    } else {
      reportData = await ReportService.getInstance().executeReport(
        {
          chartType: widget.type?.toUpperCase(),
          dataTables: widget.dataTables,
          filters: widget.filters,
          additionalFilters: !isEmptyObject(additionalFilter) ? additionalFilter : getDefaultAdditionalFilter() ,
          customFieldFilters: widget?.customFieldFilters,
          toDate: getDateStrFromDate(new Date(),DATE_FORMATS['YYYY-MM-DD']),
          logicalOperator: widget.logicalOperator || FILTER_LOGICAL_OPERATORS.AND,
          sortBy: getSortData()
        },
        {
          skipInterceptor: true,
          page: widget.paginationData?.currentPage || 1,
          limit: MAX_WIDGET_DATA_PAGE_SIZE
        },
        getAppName()
      );
    }

      setInputData(reportData?.data || []);
      setInputAdditionalData(reportData?.additionalFields || []);
      widget.paginationData = {
        pageSize: MAX_WIDGET_DATA_PAGE_SIZE,
        currentPage: widget.paginationData?.currentPage || 1,
        totalCount: reportData?.['totalCount'],
        totalPages: Math.ceil(
          (reportData?.['totalCount'] || 0) / MAX_WIDGET_DATA_PAGE_SIZE
        )
      };
      if (widget.paginationData.currentPage > 1 && widget.paginationData.currentPage > widget.paginationData.totalPages) {
        widget.paginationData.currentPage = widget.paginationData.totalPages;
        onDataSourceSave(widget, false, true, false);
      } else {
        onDataSourceSave(widget, true, true, false);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getReportDateFilterString=(param:any)=>{
    let finalString = '';
    if(param.date.startDate) {
      
      finalString += `dateOfJoining=${getFormattedDateString(
        param.date.startDate,
        DATE_FORMATS['DD-MM-YYYY'],
        DATE_FORMATS['YYYY-MM-DD']
      )},`
    }
    if(param.date.endDate) {
      finalString += `dateOfJoining=${getFormattedDateString(
        param.date.endDate,
        DATE_FORMATS['DD-MM-YYYY'],
        DATE_FORMATS['YYYY-MM-DD']
      )},`
    }
    return finalString;
  }

  const getColumnValue =(widget:any, col:any,keys:any, row:any)=>{
    if(col.type === INPUT_TYPE.SELECT) {
      if(keys[1] === "tenant_id" || TABLES_TO_REPLACE_DROPDOWN_FIELD_CONFIG[widget.tableName]?.includes(keys[1])) {
        return [String(row[keys[1]])] 
      } else if(widget.tableName !== AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS) {
        return row[keys[1]]
      } else {
        return [row[keys[1]]]
      }
    } else {
      return row[keys[1]];
    }    
  }

  useEffect(() => {
    executeReportData();
  }, [widget.refreshCount]);

  useEffect(() => {
    if (!enableNameEdit) return;

    widgetNameInputRef.current?.focus();
  }, [enableNameEdit]);

  const onBlurNameInput = (e: any) => {
    if (!e.target) return false;

    const widgetName = e.target.textContent;
    onDataSourceSave({ ...widget, widgetName }, true);
    setEnableNameEdit(false);
  };
  const getAdditionFilterColumn = (tableName:any) => {
    let additionalFilters: any = allTables?.find(table => table.tableName === tableName)?.additionalFilters || [];

    additionalFilters = additionalFilters?.map((filter: any) => {
     let searchString = '';
      return {
        additionFilter:true,
        display: filter.displayName.split(' ')?.[0],
        name: filter.tableColumn,
        data_type: getDataType(filter),
        valuesArray:filter?.valuesArray,
        possible_values: filter?.valuesArray?.map((opt: any) => opt.value),
        allowFilter :true,
        dropdownConfig: filter.callApi ? {
          data: [],
          allowSearch: true,
          searchableKey: 'name',
          searchApiConfig: {
            method: "GET",
            getUrl: (query:any) => {
              searchString = query;
              const startIndex = filter?.apiUrl?.indexOf('{');
                const toIndex = filter?.apiUrl?.indexOf('}');
                let apiUrl = filter?.apiUrl?.substring(0, startIndex) + searchString + filter?.apiUrl.substring(toIndex + 1);
                apiUrl = apiUrl?.replace('/v1/', '');
              return (
                ApiConstants.BASE + `${apiUrl}`);
            },
            dataParser: (response: any) => {
              return response.content;
            },
            renderer: (index: number, option: any) => (
              <DKLabel text={option.name} />
            ),
          },
          onSelect: () => {},
          renderer: (index: number, option: any) => (
            <DKLabel text={option.name} />
          ),
        } : null
      }
    })
    return additionalFilters;
  }
  const convertFilterData = (filters: any, libToFilter = true) => {
    let data = [];
    const copyOfFilters = deepClone(filters);
    data = copyOfFilters.map((filter: any, index: number) => {
      let value = filter.value;

      if (libToFilter) {
        const [columName, tableName] = filter.key.split('__');
        let allColumns: any = allTables.find(table => table.tableName === tableName)?.fields || [];
        allColumns = allColumns.filter((filter:any) => (filter.showInFilters || filter.showInFilters === null));
        let additionalFilters: any = getAdditionFilterColumn(tableName);
        allColumns = [...allColumns, ...additionalFilters];

        const column = allColumns.find(
          (column: any) => column.name === columName
        );
        if (column
          && [INPUT_TYPE.SELECT, INPUT_TYPE.MULTI_SELECT, 'multi_select', CUSTOM_FIELD_TYPE.DROPDOWN].includes(column.data_type)
          && value?.length > 0
          && column.possible_values?.length > 0
        ) {
          value = column?.additionFilter && column?.data_type === 'select' ? column.valuesArray?.[value[0]]?.key : column.possible_values[value[0]];
        }

        let dataType = column?.data_type?.toUpperCase();
        if (dataType === CUSTOM_FIELD_TYPE.TEXT) {
          dataType = FILTER_DATA_TYPE.STRING;
        }

        return {
          index,
          condition: LIB_TO_REPORT_OP_CONV[filter.condition],
          tableColumn: columName,
          code: columName,
          tableName: tableName,
          dataType: dataType,
          valuesArray: [],
          customField: column?.customField,
          additionFilter: additionalFilters.some((filter:any) => filter.name === columName) ? true : false,
          value: value
        };
      } else {
        let allColumns: any = allTables.find(table => table.tableName === filter.tableName)?.fields || [];
        allColumns = allColumns.filter((filter:any) => (filter.showInFilters || filter.showInFilters === null));

        let additionalFilters: any = getAdditionFilterColumn(filter.tableName);
        allColumns = [...allColumns, ...additionalFilters];
        const column = allColumns.find(
          (column: any) => (column.name === filter.tableColumn || column.name === filter.code)
        );

        if (
          value &&
          [FILTER_DATA_TYPE.SELECT, FILTER_DATA_TYPE.MULTI_SELECT, FILTER_DATA_TYPE.DROPDOWN].includes(filter.dataType) &&
          (column?.name !== 'tenant_id' || TABLES_TO_REPLACE_DROPDOWN_FIELD_CONFIG[widget.tableName]?.includes(column?.name))
        ) {
          const optionIndex = column?.additionFilter && column?.data_type === 'select' ? column.valuesArray.findIndex((val:any)=> val.key === value) :  (column?.possible_values || []).findIndex((option: string) => option === value);
          value = optionIndex === -1 ? [] : [optionIndex];
        }

        return ({
          ...filter,
          index,
          condition: REPORT_TO_LIB_OP_CONV[filter.condition],
          key: `${filter.tableColumn}__${filter.tableName || filter.code}`,
          value: (filter.dataType === FILTER_DATA_TYPE.DATE || isDate(value)) ? new Date(value) : value
        });
      }
    });
    return data;
  };
  const exportAiReport = () => {
    if (widget.aiReportWidgetId) {
      const columnsToExport:any = []
      widget?.columns?.forEach(key=>{
        widget.columnConfig?.forEach(config=>{
          if(config?.id?.split("__")[1] === key) {
            const optionsToExport = config.options.map((op:any)=> {
              if(typeof op.name === 'object') {
                return op.name
              } else {
                return op
              }
            })
            columnsToExport.push({id: key, name: config.name, type: config.type, options: optionsToExport });
          }
        })
      })
      ReportService.getInstance()
        .exportAiReportByMessageId(widget.aiReportWidgetId,{columnsToExport: columnsToExport, filters: widget.filters})
        .then(
          (res: any) => {
            if (res?.reply_message?.sqlQuerySaveResponse?.url) {
              const filePath = res?.reply_message?.sqlQuerySaveResponse?.url;
              downloadFile(filePath, getFileNameFromURL(filePath));
            }
          },
          (error) => {
            showToast("Report unable to export!", TOAST_TYPE.FAILURE);
          }
        );
    } else {
      executeReportData(true);
    }
  };

  const getFileNameFromURL = (url: any) => {
    const urlObj = new URL(url);
    return `${widget.displayName}_${urlObj.pathname.split('/').pop()}`;
  };

  const downloadFile = (url: any, fileName: any) => {
    // Create an anchor element
    const a = document.createElement('a');
    a.href = url;

    // Set the download attribute with the file name
    a.download = fileName || 'download';
    // Append the anchor to the body (not strictly necessary)
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor from the DOM
    document.body.removeChild(a);

    showToast("Report successfully exported!", TOAST_TYPE.SUCCESS);
  };

  const exportWidget = () => {
    const appName = getAppName();

    if (isEmpty(widget.columns) || isEmpty(appName)) return;

    if (aiReport) {
      exportAiReport();
      return;
    }
    const sortData = getParsedDataForAction(widget).filter(column => (column.type !== 'select' && !column.customField));
    const sortByDataType = sortData.find(data => data.name === widget.sortBy?.column)?.type || 'text';

    const exportData = getParsedDataForAction(widget);
    let filterdUniqueColumnConfig = widget?.columnConfig?.reduce((column, o) => {
      if(!column.some((obj:any) => obj.columnCode === o.columnCode && obj.name === o.name)) {
        column.push(o);
      }
      return column;
    }, []);
    widget.columnConfig = filterdUniqueColumnConfig;
    const payload = {
      reportMetadata: {
        chartType: 'TABLE',
        dataTables: widget?.dataTables,
        filters: widget.filters || [],
        customFieldFilters: widget.customFieldFilters || [],
        additionalFilter: widget.additionalFilter || [],
        logicalOperator: widget.logicalOperator || FILTER_LOGICAL_OPERATORS.AND,
        sortBy: widget.sortBy?.column
          ? {
              column: widget.sortBy.column,
              table: widget.sortBy.table,
              dataType: (sortByDataType === 'text'
                ? 'string'
                : sortByDataType
              )?.toUpperCase?.(),
              direction:
                widget.sortBy.direction === 'DEFAULT'
                  ? SORT_OPTIONS.DEFAULT
                  : widget.sortBy.direction
            }
          : null
      },
      exportColumnConfig: exportData?.map((column, index) => ({
            display: column.displayName,
            index,
            key: `${column.table}__${column.name}`
          })),
      fileType: 'XLSX'
    };

    if (appName === APP_NAME.CRM3 && !payload.reportMetadata.sortBy) {
      payload.reportMetadata.sortBy = {
        column: 'created_at',
        table: widget?.dataTables?.[0]?.name || '',
        dataType: 'DATE',
        direction: SORT_OPTIONS.DESC
      };
    }

    const fileName = `${getWidgetName()}_${new Date().getTime()}`;

    return ReportService.getInstance().exportData(payload, appName, fileName);
  };

  const getWidgetHeight = () => {
    return columnLayout === COLUMN_LAYOUT.ONE || widget.isExpanded
      ? 600
      : columnLayout === COLUMN_LAYOUT.TWO
      ? 360
      : 260;
  };

  const getWidgetWidth = () => {
    let width: string;
    if (columnLayout === COLUMN_LAYOUT.ONE) {
      width = '100%';
    } else {
      if (columnLayout === COLUMN_LAYOUT.TWO) {
        width = '49%';
      } else {
        width = '32%';
      }
    }

    return width;
  };

  const renderFilterPopup = () => {

    const filterData = getParsedDataForAction(widget,true).map(
      data => ({ ...data, id: `${data.id}__${data.table}`, name: data.displayName })
    ).filter(
      (column) => [
        INPUT_TYPE.TEXT,
        INPUT_TYPE.NUMBER,
        INPUT_TYPE.SELECT,
        INPUT_TYPE.MULTI_SELECT,
        INPUT_TYPE.DROPDOWN,
        INPUT_TYPE.DATE
      ].includes(column.type)
    );

    return (
      <div
        className="position-absolute parent-width"
        style={{
          minWidth: isViewportMobile() ? "90vw" : columnLayout === COLUMN_LAYOUT.THREE ? 400 : 480,
          right: isViewportMobile() ? 0 :
            columnLayout === COLUMN_LAYOUT.THREE
              ? 'unset'
              : `${window.innerWidth - filterRef.current?.offsetLeft - 475}px`,
          left: isViewportMobile() ? '5vw' :
            columnLayout === COLUMN_LAYOUT.THREE
              ? `${filterRef.current?.offsetLeft - 100}px`
              : 'unset',
          maxWidth: isViewportMobile() ? "90vw" : columnLayout === COLUMN_LAYOUT.THREE ? 400 : 500
        }}
      >
        <DKFilterPopup
          headers={filterData}
          filterData={convertFilterData([...widget.filters || [],...widget.customFieldFilters || [], ...widget.additionalFilter || []], false)}
          filterOperator={(widget.logicalOperator || FILTER_LOGICAL_OPERATORS.AND).toLowerCase()}
          onCancel={() => {
            setShowFilterPopup(false);
          }}
          onSubmit={(data: any, operator: FILTER_LOGICAL_OPERATORS) => {
            if(aiReport) {
              let updatedReportHeaderState = reportHeaderState;
              setReportHeaderState({
                ...updatedReportHeaderState,
                filterChangeCount: (updatedReportHeaderState.filterChangeCount || 0) + 1
              });
            }
            onFilter(convertFilterData(data, true), operator);
            setShowFilterPopup(false);
          }}
        />
      </div>
    );
  };
  function getParsedDataTypeForCustomField(dataType: string) {
    dataType = (dataType || INPUT_TYPE.TEXT).toLowerCase();

    switch(dataType) {
      case 'string':
        return INPUT_TYPE.TEXT;
      case 'dropdown':
        return INPUT_TYPE.SELECT;
      default:
        return dataType;
    }
  }
  function getDataType(filter:any) {
    if(filter.dataType?.toLowerCase() === 'string'){
      return INPUT_TYPE.DROPDOWN
    }
    if(filter.dataType?.toLowerCase() === 'duration'){
      return INPUT_TYPE.SELECT
    }

  }
  function getParsedDataForAction(widget: IWidget,isFilter=false) {
    let dataToSort: any[] = [];
    let tableModify:any = {
      columnCode: 'reportingManagerId',
      options: peopleUsers,
      orgKey:'tenant_id',
      orgList: hasMultiTenantAccess?.orgs || [],
      fieldConfigs: peopleEmployeeFieldsData?.fieldConfigs
    }
    widget.dataTables?.forEach(table1 => {
      tableModify['tableName'] = table1.name
      const peopleRoleWiseColumns = modifyPeopleColumn(
        peopleEmployeeFieldsData?.fieldConfigs,
        tableModify,
        widget.tableName
      );
      

      let allColumns: any = allTables?.find((table) => table1.name === table.tableName)?.fields
      switch (table1.name) {
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS:
          allColumns = peopleRoleWiseColumns;
          break;
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT:
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_PROMOTION_STATUS:
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_STATUS:
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_TERMINATION_STATUS:
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_TYPE:
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION:
          table1 = { ...table1 };
          allColumns = modifyPeopleOtherReportColumn(allTables?.find((table) => table1.name === table.tableName)?.fields, tableModify);
          break;
        default:
          break;
      }
      if(isFilter) allColumns = allColumns.filter((filter:any) => (filter.showInFilters || filter.showInFilters === null));
      let additionalFilters:any = allTables?.find(table => table1.name === table.tableName)?.additionalFilters || [];
      let additionalColumns = additionalFilters.map((filter: any) => filter.tableColumn);

      additionalFilters = additionalFilters?.map((filter: any) => {
       let searchString = '';
        return {
          additionFilter:true,
          display: filter.displayName.split(' ')?.[0],
          name: filter.tableColumn,
          data_type: getDataType(filter),
          valuesArray:filter?.valuesArray || [],
          possible_values: filter?.valuesArray?.map((opt: any) => opt.value),
          allowFilter :true,
          dropdownConfig: filter.callApi ? {
            data: [],
            allowSearch: true,
            searchableKey: 'name',
            searchApiConfig: {
              method: "GET",
              getUrl: (query:any) => {
                searchString = query;
                const startIndex = filter?.apiUrl?.indexOf('{');
                const toIndex = filter?.apiUrl?.indexOf('}');
                let apiUrl = filter?.apiUrl?.substring(0, startIndex) + searchString + filter?.apiUrl.substring(toIndex + 1);
                apiUrl = apiUrl.replace('/v1/', '');
                return (
                  ApiConstants.BASE + `${apiUrl}`);
              },
              dataParser: (response: any) => {
                return response.content;
              },
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              ),
            },
            onSelect: () => {},
            renderer: (index: number, option: any) => {
              return  <DKLabel text={option.name} />
            },
          } : null
        }
      })
      if(aiReport && table1.name !== AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS ) {
        allColumns = allColumns.filter((col:any)=>ALLOW_PEOPLE_REPORT_FILTER_COLUMN.includes(col.name))
      }
      let columnsWithAdditionalCol = allColumns.map((column: any) => column.name)?.concat(isFilter ? additionalColumns : []);
      additionalFilters = isFilter ? [...additionalFilters] : []
      allColumns = [...allColumns, ...additionalFilters];

      const columns = allColumns?.reduce((acc: any, col:any) => {
        acc[col.name] = col;
        return acc;
      }, {});

      dataToSort.push(
        ...columnsWithAdditionalCol.map((column: any) => ({
          id: column,
          name: column,
          table: table1.name,
          displayName: columns[column]?.display,
          type:
            (columns[column]?.data_type === 'string'
              ? 'text'
              : columns[column]?.data_type) || 'number',
          options:
            columns[column]?.possible_values?.map(
              (opt: any, index: number) => {
                return { 
                  id: opt?.id ? opt.id : index,
                  name: opt?.name ? opt.name : opt
                }
              }
            ) || [],
          valuesArray: columns[column]?.valuesArray || [],
          dropdownConfig: columns[column]?.dropdownConfig || [],
          customField: columns[column]?.customField
        }))
      );
      if (!isEmpty(table1?.customFieldColumns)) {
        dataToSort.push(...(table1?.customFieldColumns?.map((column: any) => ({
          id: column,
          name: column,
          table: table1.name,
          displayName: columns[column]?.display,
          type: getParsedDataTypeForCustomField(columns[column]?.data_type),
          options: columns[column]?.possible_values?.map((opt: string, index: number) => ({ id: index, name: opt })) || [],
          customField: columns[column]?.customField
        })).filter((column: any) => !isEmpty(column.displayName))))
      }
    });
    return dataToSort;
  }

  const renderSortPopup = () => {
    const sortData = getParsedDataForAction(widget).filter(column => (column.type !== 'select' && !column.customField));
    const sortSegments: string[] = [SORT_OPTIONS.ASC, SORT_OPTIONS.DESC];

    return (
      <div
        className="column align-items-center position-absolute bg-white border-m border-radius-s shadow-s z-index-3 pt-r"
        style={{
          width: 150,
          top: 40,
          right: '-100%'
        }}
      >
        <DKLabel text={'Sort By:'} className={'fw-m mr-auto ml-xl ' + (widget.sortBy?.column ? " mb-m " : "")} />
        {!widget.sortBy?.column ? null : <DKSegmentControl
          width={100}
          segments={sortSegments}
          selectedColor={'text-white'}
          barColor={'bg-button'}
          color={'text-dark-gray'}
          selectedIndex={sortSegments.indexOf(
            widget.sortBy?.direction || SORT_OPTIONS.DEFAULT
          )}
          onSelect={(index: number) => {
            const selectedSegment = sortSegments[index];
            setShowSortPopup(false);
            if (!widget.sortBy?.column) return;
            onSortApply({ ...widget.sortBy, direction: selectedSegment });
          }}
        />}
        <DKListPicker2
          className="parent-width p-h-s"
          data={sortData}
          displayKey={'displayName'}
          style={{
            minWidth: 120
          }}
          renderer={(index: number, columnData: any) => (
            <DKLabel
              text={`${columnData['displayName']}`}
              className={widget.sortBy?.column === columnData.id && widget.sortBy?.table === columnData?.table ? 'fw-m' : ''}
            />
          )}
          onSelect={(index: number, columnData: IColumnShortData) => {
            onSortApply({
              table: columnData?.table,
              column: columnData.id,
              direction: widget.sortBy?.direction || SORT_OPTIONS.DEFAULT
            });
            setShowSortPopup(false);
          }}
          onClose={() => setTimeout(() => setShowSortPopup(false), 10)}
        />
      </div>
    );
  };

  const renderMoreOptions = () => {
    const moreOptions = [
      {
        id: 'copy',
        title: 'Copy',
        icon: DKIcons.ic_copy,
        hidden: isSystemReport,
        onClick: () => onCopyWidget(getWidgetName(), widgetIndex)
      },
      {
        id: 'refresh',
        title: 'Refresh',
        icon: DKIcons.ic_repeat,
        needTableColumns: true,
        onClick: () => executeReportData()
      },
      {
        id: 'export',
        title: 'Export',
        icon: DKIcons.ic_export,
        needTableColumns: true,
        hidden: false,
        onClick: () => exportWidget()
      },
      {
        id: 'delete',
        title: 'Delete',
        icon: DKIcons.ic_delete,
        hidden: isSystemReport,
        onClick: () => onDeleteWidget(widgetIndex)
      }
    ];

    if(widget.tableName === AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS) {
      moreOptions.push({
        id: 'dateRangeClear',
        title: 'Clear Date Filter',
        icon: DKIcons.ic_calendar,
        hidden: isSystemReport,
        onClick: () => {
          if (loading) {
            return;
          }
          widget.dateRange = {
            startDate: '',
            endDate: ''
          }
          setReportHeaderState({
            ...reportHeaderState,
            date: {
              startDate: ``,
              endDate: ``,
            }
          })
          if(onFilterDateApplied) {
            onFilterDateApplied(null,widgetIndex);
          }
        }
      })
    }

    const isTableColumnsUnselected =
      isEmpty(widget.tableName) || isEmpty(widget.columns);
    return (
      <DKListPicker2
        data={moreOptions.filter(
          (option) => !option.hidden &&  (!option.needTableColumns || !isTableColumnsUnselected)
        )}
        className="position-absolute z-index-3 border-m border-radius-s shadow-s"
        style={{
          top: 30,
          right: 0,
          width: 150
        }}
        renderer={(index: number, option: any) => (
          <DKButton
            style={{ padding: 0 }}
            title={option.title}
            icon={option.icon}
            onClick={() => {}}
          />
        )}
        onSelect={(index: number, option: any) => {
          option.onClick?.();
          setShowMoreOptions(false);
        }}
        onClose={() => setShowMoreOptions(false)}
      />
    );
  };

  function getWidgetName() {
    let currentTable = allTables.find(table => table.tableName === widget.tableName);
    if (widget.widgetName) return widget.widgetName;
    if (!widget.displayName) {
      return currentTable?.displayName || 'Widget name';
    } else {
      return widget.displayName
    }
  }

  function getTotalRecordCount() {
    if(widget.tableName === AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS && !loading) {
      return (
        <DKLabel
          className="fw-m fs-m "
          text={
            widget.paginationData?.totalCount
              ? ` (${widget.paginationData?.totalCount})`
              : ''
          }
        />
      );
    }
  }

  const showHideCalendar = (value: boolean) => {
    const updatedState = reportHeaderState;
    updatedState.showCalendar = value;
    setReportHeaderState({ ...updatedState });
  };
  const updateDateRange = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const updatedState = reportHeaderState;
      updatedState.date.startDate = getDateStrFromDate(
        startDate,
        DATE_FORMATS['DD-MM-YYYY']
      );
      updatedState.date.endDate = getDateStrFromDate(
        endDate,
        DATE_FORMATS['DD-MM-YYYY']
      );
      updatedState.showCalendar = false;
      widget.dateRange = {
        startDate: updatedState.date.startDate,
        endDate: updatedState.date.endDate
      }
      setReportHeaderState({ 
        ...updatedState, 
        filterChangeCount: (updatedState.filterChangeCount || 0) + 1
      });
      if(onFilterDateApplied) {
        onFilterDateApplied(null,widgetIndex);
      }
      
    }
  };
  const getDateRangePicker = () => {
    return (
      <div
        className={`position-absolute bg-white border-m border-radius-s shadow-s z-index-3`}
        style={{
          top: 20,
          left: -427
        }}
      >
        <DKDateRangePicker
          className="border shadow "
          onClose={() => {
            showHideCalendar(false);
          }}
          color={'rgb(56, 79, 190)'}
          selectedDate={new Date()}
          showPresetList={true}
          startDate={reportHeaderState.date.startDate ? getDateFromString(
            reportHeaderState.date.startDate,
            DATE_FORMATS['DD-MM-YYYY']
          ):''}
          selectedStartDate={reportHeaderState.date.startDate ? getDateFromString(
            reportHeaderState.date.startDate,
            DATE_FORMATS['DD-MM-YYYY']
          ):''}
          selectedEndDate={reportHeaderState.date.endDate ? getDateFromString(
            reportHeaderState.date.endDate,
            DATE_FORMATS['DD-MM-YYYY']
          ):''}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            updateDateRange(startDate, endDate);
            
          }}
        />
      </div>
    );
  };
  const onPageTapped = useCallback(
    (pageData: { page: number }) => {
      if (loading) return;

      onDataSourceSave(
        {
          ...widget,
          paginationData: {
            totalPages: widget.paginationData?.totalPages as number,
            pageSize: widget.paginationData?.pageSize as number,
            currentPage: pageData.page,
            totalCount: widget.paginationData?.totalCount as number
          }
        },
        false,
        true,
        false
      );
    },
    [loading, widget]
  );
  const isFilterView = (filterName: any) => {
    if (aiReport) {
      if (
        (filterName === 'dateRange') &&
        widget.tableName === AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS &&
        widget.columns.length
      ) {
        return false;
      } else if (
        (filterName === 'column-filter') &&
        widget.columns.length
      ) {
        return false;
      }
       else if (
        (filterName === 'dateRange') &&
          widget.tableName ===
            AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION &&
        widget.columns.length
      ) {
        return false;
      } else if (
        filterName === 'multiTenant' &&
        hasMultiTenantAccess &&
        hasMultiTenantAccess.orgs &&
        hasMultiTenantAccess.orgs.length > 1 &&
        widget.columns.length
      ) {
        return false;
      } else if (
        filterName !== 'dateRange' &&
        filterName !== 'multiTenant' &&
        filterName !== 'column-filter' &&
        filterName !== 'column-sort'
      ) {
        return !widget.columns.length;
      } else {
        return true;
      }
    } else {
      if (filterName === 'dateRange' || filterName === 'multiTenant') {
        return true;
      } else {
        return !widget.columns.length;
      }
    }
  };
  const getDateRangeFilterMsg = () => {
    let tootipMsg = `<div class='fw-b'>Date range filter</div><div>`
    switch(widget.tableName) {
        case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS:
        tootipMsg += 'View employee details by their Date of Joining.</div>';
        break;
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION:
        tootipMsg += 'View employee details by their Date of Termination.</div>';
        break;
      default:
        tootipMsg = '';
    }
    return tootipMsg;
  }
  return (
    <div
      id={`draggable-widget-${widget.id}`}
      key={key}
      className={
        'column mobile-parent-width border-m border-radius-m shadow-s unselectable ' +
        (widget.type ? 'bg-white' : 'bg-gray1')
      }
      style={{
        overflow: 'visible',
        width: widget.isExpanded ? '100%' : getWidgetWidth(),
        height: getWidgetHeight()
      }}
    >
      <div className="row justify-content-between p-h-r p-v-s">
        <div
          className={
            'row width-auto widget-name-container text-align-left ' +
            (isSystemReport ? '' : 'cursor-hand')
          }
          style={{ minHeight: 32 }}
        >
          <div
            className={
              'fw-m fs-m ' +
              (columnLayout === COLUMN_LAYOUT.THREE ? ' text-ellipsis ' : '')
            }
            ref={widgetNameInputRef}
            contentEditable={enableNameEdit}
            onDoubleClick={(e) =>
              isSystemReport ? {} : setEnableNameEdit(true)
            }
            onBlur={onBlurNameInput}
            style={{
              wordBreak: 'break-word',
              WebkitLineClamp:
                columnLayout === COLUMN_LAYOUT.THREE ? 1 : 'unset'
            }}
          >
            {getWidgetName()}
          </div>
          {isSystemReport ? null : (
            <DKIcon
              src={DKIcons.ic_edit}
              className="ic-s widget-name-action cursor-hand m-h-r opacity-5"
              onClick={() => setEnableNameEdit(true)}
            />
          )}
          {getTotalRecordCount()}
        </div>
        {loading ? (
          <DKSpinner className="ml-r mr-auto" iconClassName="ic-s-2" />
        ) : null}
        <div className="row width-auto">
          {isFilterView('multiTenant') ? null : (
            <div
              className={`position-relative border-radius-m border-m d-flex align-items-center ${
                loading ? 'pointer-events-none' : ''
              }`}
              ref={filterRef}
            >
              <DKLabel text={'Local'} className="m-s mr-xs" />
              <Toggle
                className={'m-v-xs'}
                isOn={widget.isMultiTenantData}
                onChange={() => {
                  if (loading) {
                    return;
                  }
                  let updatedReportHeaderState = reportHeaderState;
                  updatedReportHeaderState.isMultiTenantData =
                    !widget.isMultiTenantData;
                  setReportHeaderState(updatedReportHeaderState);
                  if (onToggleGlobalLocalReport) {
                    onToggleGlobalLocalReport(null, widgetIndex);
                  }
                }}
              />

              <DKLabel text={'Global'} className="m-s ml-xs" />
            </div>
          )}
          {isFilterView('dateRange') ? null : (
            <div className="position-relative" ref={filterRef}>
              <DKTooltipWrapper
                  content={getDateRangeFilterMsg()}
                  tooltipClassName=""
                >
                <DKButton
                  // title={`${reportHeaderState.date.startDate} - ${reportHeaderState.date.endDate} `}
                  icon={DKIcons.ic_calendar}
                  className="opacity-5 "
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    showHideCalendar(true);
                  }}
                />
                </DKTooltipWrapper>
              
              {isEmpty(widget.dateRange?.startDate)
              ? null : (
                <div
                  className="position-absolute bg-red circle"
                  style={{
                    width: 5,
                    height: 5,
                    top: 6,
                    left: 20
                  }}
                ></div>
              )}
              {reportHeaderState.showCalendar && getDateRangePicker()}
            </div>
          )}

          {isFilterView('column-filter') ? null : (
            <div className="position-relative" ref={filterRef}>
              <DKButton
                icon={DKIcons.ic_filter}
                className="opacity-5"
                onClick={() => {
                  setShowFilterPopup(true);
                }}
              />
              {isEmpty(widget.filters) &&
              isEmpty(widget.customFieldFilters) &&
              isEmpty(widget.additionalFilter) ? null : (
                <div
                  className="position-absolute bg-red circle"
                  style={{
                    width: 5,
                    height: 5,
                    top: 6,
                    left: 20
                  }}
                ></div>
              )}
            </div>
          )}
          {isFilterView('column-sort') ? null : (
            <div className="position-relative">
              <DKButton
                icon={DKIcons.ic_sort}
                className="opacity-5"
                onClick={() => setShowSortPopup(!showSortPopup)}
              />
              {widget.sortBy?.column ? (
                <div
                  className="position-absolute bg-red circle"
                  style={{
                    width: 5,
                    height: 5,
                    top: 7,
                    left: 18
                  }}
                ></div>
              ) : null}
              {showSortPopup && renderSortPopup()}
            </div>
          )}
          {!isSystemReport && (
            <DKButton
              icon={DKIcons.ic_edit}
              className="opacity-5"
              onClick={() => setShowDataSourcePicker(true)}
            />
          )}
          <DKButton
            icon={!widget.isExpanded ? ic_expand : ic_collapsed}
            className="opacity-5"
            onClick={() =>
              toggleWidgetExpandedState(!widget.isExpanded, widgetIndex)
            }
            style={{ transform: 'rotate(90deg)' }}
          />
          <DragHandle />
          <div className="position-relative">
            <DKButton
              icon={DKIcons.ic_more}
              className="opacity-5"
              onClick={() => setShowMoreOptions(true)}
            />
            {showMoreOptions ? renderMoreOptions() : null}
          </div>
        </div>
      </div>
      {!widget.type && (
        <div className="flex-1 column justify-content-center align-items-center parent-width">
          <DKLabel text="No data source selected" className="text-gray" />
          <DKButton
            className="bg-button text-white mt-m"
            title="Data Source"
            icon={DKIcons.white.ic_reports}
            onClick={() => setShowDataSourcePicker(true)}
          />
        </div>
      )}
      {loading && isEmpty(inputData) ? (
        <div
          className="column align-items-center justify-content-center parent-width flex-1 p-h-r"
          style={{
            height: `calc(100% - 48px)`
          }}
        >
          <DKSpinner title="fetching data.." />
        </div>
      ) : widget.type ? (
        <div
          className="column align-items-center justify-content-center parent-width flex-1 p-h-r"
          style={{
            height: `calc(100% - 48px)`
          }}
        >
          <ChartHolder
            chartData={widget}
            inputData={inputData || []}
            additionalData={inputAdditionalData || []}
            allowGridEdit={!isSystemReport}
            height={
              getWidgetHeight() -
              (widget.paginationData && widget.paginationData.totalPages > 1
                ? 100
                : 60)
            }
            onGridColumnUpdate={(tableColumns: any[]) =>
              isSystemReport
                ? {}
                : onDataSourceSave(
                    {
                      ...widget,
                      columnConfig: tableColumns
                    },
                    true
                  )
            }
            onSortApplied={({ order, columnData }: any) =>
              onSortApply({
                direction: order,
                column: columnData.columnCode,
                table: columnData.tableName || widget.tableName
              })
            }
          />

          {(
            aiReport
              ? widget?.tableName ===
                  AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS &&
                widget.paginationData &&
                widget.paginationData.totalPages > 1
              : widget.paginationData && widget.paginationData.totalPages > 1
          ) ? (
            <DKPaginator
              currentPage={widget?.paginationData?.currentPage || 1}
              totalCount={widget?.paginationData?.totalPages}
              className="width-auto ml-auto pb-r"
              style={{
                cursor: loading ? 'not-allowed' : 'default'
              }}
              size={24}
              iconSize={24}
              onPageTapped={onPageTapped}
            />
          ) : null}
        </div>
      ) : null}
      {showDataSourcePicker ? (
        <DataSourcePopup
          dataSource={widget}
          onClose={() => setShowDataSourcePicker(false)}
          aiReport={aiReport}
          onSave={(widgetData: IWidget) => {
            onDataSourceSave(widgetData, false, true);
            setInputData(null);
            setShowDataSourcePicker(false);
          }}
        />
      ) : null}
      {showFilterPopup && renderFilterPopup()}
    </div>
  );
}
export default SortableElement(Widget);
const DragHandle = SortableHandle(() => (
  <div className="position-relative">
    <DKButton className="opacity-5" icon={ic_drag} />
  </div>
));
