import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    DKLabel,
    DKButton,
    DKIcon,
    DKSpinner,
    DKLine,
    DKCheckMark,
    showAlert,
    showLoader,
    removeLoader,
    INPUT_TYPE,
    DKSegmentControl
} from "deskera-ui-library";
import { SORT_OPTIONS, CHART_TYPE} from "../../constants/Enum";
import { fetchReportTablesByAppName } from "../../store/slices/tableSlice";
import { deepClone, getRandomNumber, isEmpty, isEmptyObject, modifyPeopleColumn, modifyPeopleOtherReportColumn } from "../../utilities/Common";
import NoRecordFound from "./NoRecordFound";
import { AI_CHART_TYPE_SUPPORT, AI_REPORT_TABLE_CONSTANT, AI_SUPPORTED_APPS, AI_TABLE_SUPPORTED, APPS, APP_NAME, PERMISSION_APP_NAME } from "../../constants/Constant";
import ControlledInput from "./ControlledInput";
import AuthManager from "../../managers/AuthManager";
import { IDataTable, IWidget } from "../../models/Report";
import OneDriveButton from "../app/OneDriveButton";
import AppIntegrationService from "../../services/appIntegration";
import CustomReportMapping, { showCustomMapperPopup } from "../app/CustomReportMapping";
import DropBoxButton from "../app/DropBoxButton";
import { getPeopleEmployeeFields } from "../../store/slices/recordSlice";
import { fetchMultiTenantAccess } from "../../store/slices/tenantSlice";

const CHART_DATA = [
    {
        icon: require("../../assets/icons/chart/ic_chart_1.png"),
        type: CHART_TYPE.TABLE,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_2.png"),
        type: CHART_TYPE.BAR_VERTICAL,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_3.png"),
        type: CHART_TYPE.MULTI_BAR_VERTICAL,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_4.png"),
        type: CHART_TYPE.BAR_HORIZONTAL,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_5.png"),
        type: CHART_TYPE.LINE,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_6.png"),
        type: CHART_TYPE.MULTI_BAR_HORIZONTAL,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_7.png"),
        type: CHART_TYPE.DONUT,
    },
    {
        icon: require("../../assets/icons/chart/ic_chart_8.png"),
        type: CHART_TYPE.PIE,
    },
];


export interface IDataSourcePopupProps extends PropsFromRedux {
    dataSource?: any;
    onClose: () => void,
    onSave: (widget: IWidget) => void
    aiReport?: any;
}

class DataSourcePopup extends Component<IDataSourcePopupProps, any> {
    constructor(props: IDataSourcePopupProps) {
        super(props);
        const dataTables = deepClone(props.dataSource?.dataTables || []);
        this.state = {
            selectedTables: dataTables,
            currentTable: dataTables?.[0] || null,
            selectedChartType: props.dataSource?.type || CHART_TYPE.TABLE,
            selectedTable: props.dataSource?.tableName,
            tables: props.tables || [],
            columns: this.getFilteredColumns(this.modifiedTableFields(props.dataSource?.tableName)),
            selectedAppName: dataTables?.[0]?.appName,
            appNameFilter: APP_NAME.ALL,
            selectedColumns: props.dataSource?.columns || [],
            selectedCustomColumns: props.dataSource?.customFieldColumns || [],
            showColumnSearch: false,
            showTableSearch: false,
            columnSearchText: '',
            columnConfig: props.dataSource?.columnConfig || [],
            tableSearchText: ''
        };
    }
    componentDidMount(): void {
        if (!this.isUserPermitted()) {
            return;
        }

        if (isEmpty(this.props.tables)) {
            this.fetchTableData();
        }
        if (!isEmpty(this.state.selectedTable)) {
            let element = document.getElementById(`${this.state.selectedTable}_id`);
            element?.scrollIntoView();
        }
    }
    componentWillReceiveProps(nextProps: Readonly<IDataSourcePopupProps>): void {
        if (nextProps.permissions !== this.props.permissions) {
            this.isUserPermitted(nextProps.permissions);
            return;
        }
    }
    isUserPermitted = (permissions = this.props.permissions) => {
        let flag = true;
        try {
            if (isEmpty(permissions)) return flag;

            const permittedApps = permissions.apps || [];
            const isReportBuilderPermitted = permittedApps.some(
                (app: any) => app.appName === PERMISSION_APP_NAME.REPORT_BUILDER
            );
            const isUserOwner = this.props.tenantDetails?.userId === AuthManager.token?.["iamUserId"];

            if (isReportBuilderPermitted)
                return true;

            AuthManager.getProductPermissionAlert(isUserOwner);
        } catch(err) {}

        return flag;
    }
    fetchTableData = () => {
        this.props.fetchReportTablesByAppName({
            appName: `${APP_NAME.CRM3},${APP_NAME.ERP},${APP_NAME.PEOPLE}`
        }).catch(err => {
            console.log(err);
        });
    }
    onChartTypeSelect = (type: string) => {
        this.setState({
            selectedChartType: type,
        }, () => {
           if(this.state.selectedTables?.length > 1) this.clearTableAndColumns();
        })
    }
    modifiedTableFields = (tableName:any) => {
        const tableModify = {
            columnCode: 'reportingManagerId',
            options: this.props.peopleUsers,
            orgKey:'tenant_id',
            orgList: this.props?.hasMultiTenantAccess?.orgs || [],
            tableName: tableName,
            fieldConfigs: this.props.peopleEmployeeFields?.fieldConfigs
        }
        switch(tableName) {
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS:
                return modifyPeopleColumn(this.props.peopleEmployeeFields?.fieldConfigs || [], tableModify, tableName);
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT:
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_PROMOTION_STATUS:
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_STATUS:
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_TERMINATION_STATUS:
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_TYPE:
            case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION:
            
                return modifyPeopleOtherReportColumn(this.props.tables[tableName]?.fields, tableModify) || []
            default:
                return this.props.tables[tableName]?.fields || []
        }
    }
    getFilteredColumns = (columns: any[]) => {
        return (columns || []).filter(column => column.name !== "detailed_addr");
    }
    getSortedColumns = (tableName: string) => {
        if (!tableName) return [];
        let columns = [...(this.modifiedTableFields(tableName) || [])];
        if (tableName === "crm3_contact_report") {
            columns = columns.filter(column => column.name !== "detailed_addr");
        }
        let sortedColumns: any[] = [];
        if (this.props.dataSource?.columns) {
            columns.forEach(column => {
                if (!this.props.dataSource?.columns?.includes(column.name)) {
                    sortedColumns.push(column);
                } else {
                    sortedColumns.unshift(column);
                }
            });
        }
        return sortedColumns;
    }
    onTableSelect = (table: any) => {
        const tableToPush = {
            id: table.id,
            name: table.tableName,
            displayName: table.displayName,
            appName: table.appName,
            columns: [],
            customFieldColumns: []
        };
        let { selectedTables } = this.state;
        if (this.state.selectedChartType?.toUpperCase() === CHART_TYPE.TABLE) {
            const index = selectedTables?.findIndex((item: IDataTable) => item.name === table.tableName);
            if (index === -1) {
                selectedTables.push(tableToPush);
            }
        } else {
            selectedTables = [tableToPush];
        }

        this.setState({
            selectedTables,
            currentTable: tableToPush,
            columns : this.getFilteredColumns(
                this.modifiedTableFields(tableToPush?.name)                
              )
        });
    }
    onColumnSelect = (column: any) => {
        const { selectedColumns, selectedCustomColumns } = this.state;
        let columns = (column.customField ? selectedCustomColumns : selectedColumns) || [];
        const index = columns.indexOf(column.name);
        if (index === -1) {
            columns.push(column.name);
        } else {
            columns.splice(index, 1);
        }
        this.setState({ [column.customField ? 'selectedCustomColumns' : 'selectedColumns']: columns });
    }
    getFieldDataType = (field:any) => {
        switch (field.data_type) {
          case INPUT_TYPE.DATE:
          case INPUT_TYPE.NUMBER:
            return field.data_type;
          case INPUT_TYPE.SELECT:
            if (!isEmptyObject(field?.possible_values)) {
              return INPUT_TYPE.SELECT;
            } else {
              return INPUT_TYPE.TEXT
            }
          default:
            return INPUT_TYPE.TEXT;
        }
    }
    getColumnConfig = (column: any) => {
        const { currentTable } = this.state;
        let columnConfig: any = [...this.state.columnConfig];
        let index = columnConfig?.findIndex((col: any) => col.name === column.name);
        if (index >= 0) {
            columnConfig.splice(index, 1);
        } else {
            this.modifiedTableFields(currentTable.name)
            .forEach((field: any) => {
                if (field.name === column.name) {
                    columnConfig.push({
                        name:field.display,
                        type:this.getFieldDataType(field),
                        index:'',
                        options: !isEmptyObject(field?.possible_values) ? field?.possible_values?.map((opt:any, index:any) => ({ id: index, name: opt }))?.filter((obj:any) => obj.name !== '') : [],
                        required: false,
                        width: 200,
                        editable: false,
                        hidden: false,
                        uiVisible: true,
                        columnCode: `${currentTable.name}__${column.name}`,
                        key: `${currentTable.name}__${column.name}`,
                        id: `${currentTable.name}__${column.name}`,
                        allowColumnSort: field.customField ? false : true,
                        customField: field.customField,
                        textAlign: field.data_type === INPUT_TYPE.NUMBER ? 'right' : 'left',
                        systemField: true
                    })
                }
            })
        }
        return columnConfig;
    }
    onColumnSelect2 = (column: any) => {
        let { selectedTables, currentTable } = this.state;
        const indexOfTable = selectedTables?.findIndex((table: any) => table.name === currentTable.name);

        if (isEmpty(selectedTables[indexOfTable])) return;

        let index;
        let columns = column.customField ? selectedTables[indexOfTable].customFieldColumns : selectedTables[indexOfTable].columns;
        columns = columns || [];
        index = columns?.findIndex((col: any) => col === column.name);

        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns = [...columns, column.name];
        }
        if (column.customField) {
            selectedTables[indexOfTable].customFieldColumns = columns;
        } else {
            selectedTables[indexOfTable].columns = columns;
        }
        selectedTables[indexOfTable]['columnCount'] = (selectedTables[indexOfTable].columns?.length || 0) + (selectedTables[indexOfTable].customFieldColumns?.length || 0);
        selectedTables = selectedTables.filter((table: any) => !isEmpty(table.columns) || !isEmpty(table.customFieldColumns) || table.name === currentTable.name);
        let columnConfig = this.getColumnConfig(column);
        this.setState({ selectedTables, columnConfig });
    }
    clearTableAndColumns = () => {
        this.setState({ currentTable: {}, selectedTables: [], columns: [] });
    }
    clearColumns = () => {
        let { selectedTables } = this.state;
        selectedTables.forEach((table:any) => {
            if (table.name === this.state.currentTable.name) {
                table.columns = [];
                table.customFieldColumns = [];
            }
        });
        this.setState({ selectedTables });
    }

    /* **************ONE DRIVE FILE SELECTION************** */
    // fetchOneDriveFilelist = (files: any[]) => {
    //     AppIntegrationService.getOneDriveFileList().then(
    //       (data: any) => {
    //         const fileForMapping = data.content?.find(
    //           (file: any) => file.fileId === files[0].id
    //         );

    //         if (!fileForMapping) return Promise.reject("File not found");

    //         showCustomMapperPopup({
    //             mappingData: [fileForMapping],
    //             onUpdate: (updatedData: any) => {
    //                 this.props.fetchReportTablesByAppName({
    //                     appName: `${APP_NAME.ERP}`
    //                 });
    //             }
    //         });
    //       }
    //     ).catch((err) => {
    //         console.error('Error fetching one drive files: ', err);
    //     }).finally(() => {
    //         removeLoader();
    //     });
    // };
    onReceiveOneDriveFileHeaders = (data: any) => {
        if (isEmpty(data?.[0]?.columnMappings)) {
            showAlert('Error saving file!', 'Invalid file selected, please note only xlsx files are supported.');
            removeLoader();
            return;
        }

        const filteredMappingsToDisplay = data[0].columnMappings.filter(
            (mapping: any) => mapping.allowToMap ?? true
        );

        if (isEmpty(filteredMappingsToDisplay)) {
            AppIntegrationService.saveOneDriveFilesColumnMapping(
                data[0].id,
                data[0].columnMappings.map(
                    (data: any) => ({
                      displayName: data?.displayName,
                      type: data?.type
                    })
                )
            ).then((data: any) => {
                removeLoader();
                this.props.fetchReportTablesByAppName({appName: `${APP_NAME.ERP}`});
            }).catch((err) => {
                removeLoader();
                showAlert('Error!', 'Error while updating column types. Please note duplicate column names are not allowed.');
                console.error('Error updating column type mapping: ', err);
              });
        } else {
            removeLoader();
            showCustomMapperPopup({
                mappingData: [data[0]],
                onUpdate: (updatedData: any) => {
                    this.props.fetchReportTablesByAppName({
                        appName: `${APP_NAME.ERP}`
                    });
                }
            });
        }
    }
    onOneDriveFileSelection = (files: any[]) => {
        // Make API call to get the configs and show column mapper
        showLoader('Saving file..');
        const oneDriveApp = this.props.appIntegrationMapping?.find(
          (app: any) => app.appName === 'ONEDRIVE'
        );

        if (!oneDriveApp) return;

        const payload = {
          appId: oneDriveApp.id,
          driveItems: files
        };

        AppIntegrationService.saveOneDriveFiles(payload).then(
          this.onReceiveOneDriveFileHeaders,
          (err: any) => {
            removeLoader();
            showAlert(
              'Error!',
              err.data.debugMessage
                ? err?.data?.debugMessage
                : 'Error saving file information, please select only xlsx files.'
            );
            console.error('Error saving file info: ', err);
          }
        );
    };

    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    onDropBoxFileSelection = (files: any[]) => {
        // Make API call to get the configs and show column mapper
        showLoader('Saving file..');
        const dropBoxApp = this.props.appIntegrationMapping?.find(
            (app: any) => app.appName === 'DROPBOX'
        );

        if (!dropBoxApp) return;

        const payload = {
            appId: dropBoxApp.id,
            driveItems: [files?.[0]]
        };

        AppIntegrationService.saveDropBoxFiles(payload).then(
            this.onReceiveOneDriveFileHeaders,
            (err: any) => {
                removeLoader();
                showAlert(
                    'Error!',
                    err.data.debugMessage
                        ? err?.data?.debugMessage
                        : 'Error saving file information, please select only xlsx files.'
                );
            }
        );
    };
    onReceiveDropBoxFileHeaders = (data: any) => {
        if (isEmpty(data?.[0]?.columnMappings)) {
            showAlert('Error saving file!', 'Invalid file selected, please note only xlsx files are supported.');
            return;
        }

        const filteredMappingsToDisplay = data[0].columnMappings.filter(
            (mapping: any) => mapping.allowToMap ?? true
        );

        if (isEmpty(filteredMappingsToDisplay)) {
            AppIntegrationService.saveDropBoxFilesColumnMapping(
                data[0].id,
                data[0].columnMappings.map(
                    (data: any) => ({
                        displayName: data?.displayName,
                        type: data?.type
                    })
                )
            ).then((data: any) => {
                removeLoader();
                this.props.fetchReportTablesByAppName({ appName: `${APP_NAME.ERP}` });
            }).catch((err) => {
                removeLoader();
                showAlert('Error!', 'Error while updating column types. Please note duplicate column names are not allowed.');
                console.error('Error updating column type mapping: ', err);
            });
        } else {
            removeLoader();
            showCustomMapperPopup({
                mappingData: [data[0]],
                onUpdate: (updatedData: any) => {
                    this.props.fetchReportTablesByAppName({
                        appName: `${APP_NAME.ERP}`
                    });
                }
            });
        }
    }
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    onSave2 = () => {
        const filteredSelectedTables = this.state.selectedTables.filter(
          (selectedTable: any) =>
            !isEmpty(selectedTable) &&
            (!isEmpty(selectedTable.columns) ||
              !isEmpty(selectedTable.customFieldColumns))
        );
        if (isEmpty(filteredSelectedTables)) {
            showAlert(`Can't Save?`, `Please select a data source & related fields to proceed.`)
            return;
        }

        let widget: IWidget;
            widget = {
                id: isEmpty(this.props.dataSource?.id) ? getRandomNumber(1000).toString() : this.props.dataSource?.id,
                appName: filteredSelectedTables[0].appName,
                tableName: filteredSelectedTables[0].name || 'defaultTable',
                displayName: filteredSelectedTables[0].displayName,
                dataTables: filteredSelectedTables,
                columns: filteredSelectedTables[0].columns || [],
                customFieldColumns: filteredSelectedTables[0].customFieldColumns || [],
                type: this.state.selectedChartType,
                columnConfig:this.state.columnConfig || [],
                filters: [],
                isExpanded: this.props.dataSource?.isExpanded ?? false,
                sortBy: null,
                theme: null,
                refreshCount: this.props.dataSource?.refreshCount ?? 0,
                aiReport: this.props.aiReport
            };
        this.props.onSave(widget);
    }
    onSave = () => {
        let widget: IWidget;
        if (isEmpty(this.props.dataSource?.id)) {
            widget = {
                id: getRandomNumber(1000).toString(),
                appName: this.state.selectedAppName,
                tableName: 'defaultTable',
                columns: this.state.selectedTables?.[0]?.columns || [],
                customFieldColumns: this.state.selectedTables?.[0]?.customFieldColumns || [],
                type: this.state.selectedChartType,
                filters: [],
                isExpanded: false,
                sortBy: {
                    column: this.state.selectedColumns?.[0],
                    direction: SORT_OPTIONS.DEFAULT,
                    table: this.state.selectedTable,
                },
                theme: null,
                aiReport: this.props.aiReport
            };
        } else {
            widget = {
                ...this.props.dataSource,
                columns: this.state.selectedColumns,
                customFieldColumns: this.state.selectedCustomColumns,
                tableName: this.state.selectedTable,
                type: this.state.selectedChartType,
                appName: this.state.selectedAppName,
                aiReport: this.props.aiReport
            }
        }

        this.props.onSave(widget);
    }
    /** renderer goes here */
    getHeader() {
        return (
            <div className="row justify-content-between p-v-s p-h-r bg-gray1">
                <DKLabel text="Select Graph & Data Source" className="fw-m fs-m" />
                <div className="row width-auto">
                    <DKButton
                        title="Cancel"
                        className=""
                        onClick={() => {
                            this.props.onClose?.();
                        }}
                    />
                    <DKButton
                        title="Save"
                        className="bg-button text-white"
                        onClick={this.onSave2}
                    />
                </div>
            </div>
        );
    }
    getGraphSelection() {
        const iconClass = "ic-m cursor-hand border-radius-s";
        const { selectedChartType } = this.state;
        const filterChartData = this.props.aiReport ? CHART_DATA.filter((chart:any) => AI_CHART_TYPE_SUPPORT.includes(chart.type)) : CHART_DATA
        return (
            <div className="column parent-width align-items-center">
                <div className="row row-responsive justify-content-center" style={{ gap: 10 }}>
                    {filterChartData.map(data => <DKIcon
                        src={data.icon}
                        className={`${iconClass} ${selectedChartType.toUpperCase() === data.type ? ' border-blue ' : ''}`}
                        onClick={() => this.onChartTypeSelect(data.type)} />)}
                </div>
            </div>
        );
    }
    getAppNameSegmentControl() {
        const apps = this.props.aiReport ? AI_SUPPORTED_APPS : APPS
        const appSegments = apps.map(app => app.name);
        const selectedIndex = apps.findIndex(app => app.code === this.state.appNameFilter)
        return (
            <div className="row justify-content-center mt-l">
                <DKSegmentControl
                    width={350}
                    segments={appSegments}
                    selectedColor={"text-white"}
                    barColor={"bg-button"}
                    color={"text-dark-gray"}
                    selectedIndex={selectedIndex}
                    onSelect={(index: number) => {
                        const selectedSegment = apps[index].code;
                        this.setState({ appNameFilter: selectedSegment });
                    }}
                />
            </div>
        )
    }
    getTableSection() {
        return (
            <div
                className="column mt-m parent-width align-items-center flex-1"
                style={{ overflow: "hidden" }}
            >
                <div
                    className="column border-radius-r border-m mt-r parent-width flex-1"
                    style={{ overflow: "hidden" }}
                >
                    <div className="row parent-height align-items-start">
                        <div
                            className="column bg-chip-oranges parent-height"
                            style={{
                                width: "40%",
                            }}
                        >
                            <div className="row justify-content-between bg-gray1">
                                <DKLabel
                                    text="Tables"
                                    className="fw-m p-s bg-gray1 parent-width"
                                />
                                {this.state.appNameFilter === APP_NAME.ONEDRIVE ? (
                                    <OneDriveButton
                                        buttonClassName="border-m mr-s bg-white fw-m"
                                        needDarkIcon={true}
                                        styles={{
                                            padding: "4px 6px"
                                        }}
                                        onSuccess={(files: any) => this.onOneDriveFileSelection(files?.value)}
                                        onCancel={() => {}}
                                        onError={() => {}}
                                    />
                                ) : null}
                                {this.state.appNameFilter === APP_NAME.DROPBOX ? (
                                    <DropBoxButton
                                        buttonClassName="border-m mr-s bg-white fw-m"
                                        needDarkIcon={true}
                                        styles={{
                                            padding: "4px 6px"
                                        }}
                                        onSuccess={(files: any) => this.onDropBoxFileSelection(files)}
                                        onCancel={() => {}}
                                        onError={() => {}}
                                    />
                                ) : null}
                            </div>
                            <div
                                className="column flex-1 parent-width hide-scroll-bar"
                                style={{ overflowX: "scroll", maxHeight: 395 }}
                            >
                                <ControlledInput
                                    placeHolder="Search by table..."
                                    className='datasource-search-bar'
                                    autoFocus={true}
                                    onChange={(e: any) => this.setState({ tableSearchText: e.target.value })}
                                    onKeyDown={() => { }}
                                    value={this.state?.tableSearchText}
                                />
                                {this.getTableList()}
                            </div>
                        </div>
                        <div
                            className="column bg-chip-blues parent-height"
                            style={{
                                width: "60%",
                                borderLeft: "1px solid rgb(220, 220, 220)",
                            }}
                        >
                            <div className="row justify-content-between bg-gray1">
                                <DKLabel text="Columns / Fields" className="fw-m p-s " />
                                <div className="column align-items-center" style={{ flexDirection: 'row' }}>

                                    {isEmpty(this.state.selectedTables?.[0]?.columns) ? null : <DKButton
                                        title="Reset"
                                        className="text-white p-0 mr-r border-radius-r"
                                        onClick={this.clearTableAndColumns}
                                        style={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            backgroundColor: "rgba(0,0,0,0.3)",
                                        }}
                                    />}
                                </div>
                            </div>
                            <div
                                className="column flex-1 parent-width hide-scroll-bar position-relative"
                                style={{ overflowX: "scroll", maxHeight: 395 }}
                            >
                                {<ControlledInput
                                    placeHolder="Search Columns / Fields..."
                                    className='datasource-search-bar'
                                    autoFocus={true}
                                    onChange={(e: any) => this.setState({ columnSearchText: e.target.value }, this.onColumnSearch)}
                                    onKeyDown={() => { }}
                                    value={this.state?.columnSearchText}
                                />}
                                {this.renderColumnList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    onColumnSearch = () => {
        if (isEmpty(this.props.tables[this.state.currentTable?.name])) return;
        let filteredColumns: any = [];

        try {
            const tableFields = this.modifiedTableFields(this.state.currentTable.name);
            const columns = [...tableFields];

            if (isEmpty(this.state.columnSearchText)) {
                filteredColumns = columns;
            } else {
                columns.forEach((column: any) => {
                    if ((column.display || "").toLowerCase().includes(this.state.columnSearchText.toLowerCase())) {
                        filteredColumns.push(column);
                    }
                })
            }

            filteredColumns = this.getFilteredColumns(filteredColumns);
        } catch(err) {
            return;
        }

        this.setState({ columns: filteredColumns });
    }
    getFilteredTablesFromSearchQuery = () => {
        const { tables } = this.props;
        let tableObject = deepClone(tables)
        if(tableObject[AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS]) {
            tableObject[AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS].fields = this.modifiedTableFields(AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS)
        }
        if(!isEmpty(tableObject)) {
            Object.keys(tableObject).forEach(key => {
                if (this.props.aiReport && !AI_TABLE_SUPPORTED.includes(key)) {
                    delete tableObject[key];
                } else if(!this.props.aiReport && AI_TABLE_SUPPORTED.includes(key)) {
                    delete tableObject[key];
                }
            });
        }
        if (isEmpty(tableObject)) return {};

        const filteredTableData = Object.values(tableObject)
            .filter((value: any) => value.displayName?.toLowerCase().includes(this.state.tableSearchText.toLowerCase()));
        let newTables = {};
        filteredTableData.reduce((prev: any, current: any) => {
            prev[current.tableName] = current
            return prev;
        }, newTables);

        return newTables;
    }
    isTableActive = (tableName: any): boolean => {
        const { selectedTables } = this.state;
        if (!selectedTables.length) return false;
        const isTableSelected = (selectedTables as IDataTable[]).find(table => table.name === tableName);
        return !isEmpty(isTableSelected) && (!isEmpty(isTableSelected?.columns) || !isEmpty(isTableSelected?.customFieldColumns));
    }
    isTableJoinable = (tableName: any) => {
        const targetTable = this.state.selectedTables?.[0];
        if (!targetTable) return true;
        const table = this.props.tables?.[targetTable.name];
        return tableName === targetTable.name || table?.joinSchema?.some((tab: any) => tab.table === tableName);
    }
    getTableList() {
        let arr: any = [];
        const filteredTables = this.getFilteredTablesFromSearchQuery();

        const newTablesWithValues = Object.values(filteredTables);
        newTablesWithValues.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
        const sortedTableKeys = newTablesWithValues.map((table:any) => table.tableName);

        sortedTableKeys?.forEach((key: string) => {
            const tableData = this.props.tables[key];

            switch (this.state.appNameFilter) {
                case APP_NAME.CRM:
                case APP_NAME.CRM3:
                    if (![APP_NAME.CRM, APP_NAME.CRM3].includes(tableData?.appName)) return;
                    break;
                case APP_NAME.ERP:
                    if (tableData?.appName !== APP_NAME.ERP) return;
                    break;
                case APP_NAME.ONEDRIVE:
                    if (!tableData?.customReport || tableData.source != APP_NAME.ONEDRIVE) return;
                    break;
                case APP_NAME.DROPBOX:
                    if (!tableData?.customReport || tableData.source != APP_NAME.DROPBOX) return;
                    break;
                default:
            }
            let enableTable = true;
            const currentTable = this.state.selectedTables?.find((table: any) => table.name === tableData.tableName);
            const isCurrentTableSelected = !!currentTable;
            const isColumnSelected = this.state.selectedTables?.some((table: any) => !isEmpty(table.columns) || !isEmpty(table.customFieldColumns));
            if (this.state.selectedTables?.length && !isCurrentTableSelected) {
                if (isColumnSelected) {
                    enableTable = false;
                }
                if (this.state.selectedChartType.toUpperCase() === CHART_TYPE.TABLE) {
                    if (this.isTableJoinable(key)) {
                        enableTable = true;
                    }
                }
            }
            arr.push(
                <div
                    key={key}
                    className={`column parent-width p-h-r cursor-hand ${(this.state.currentTable?.name === key) ? "bg-gray1" : ""} ${this.isTableActive(key) ? "fw-m text-blue": ""}`}
                    onClick={() => this.onTableSelect(tableData)}
                    id={`${key}_id`}
                    style={{
                        opacity: enableTable ? 1 : 0.5,
                        pointerEvents: enableTable ? 'all' : 'none'
                    }}
                >
                    <div className="row justify-content-between">
                    <DKLabel text={`${tableData?.displayName}`} className="mt-r" />
                        {currentTable?.columnCount ? <DKLabel text={`${currentTable?.columnCount}`} className="mt-r fs-s text-white d-flex justify-content-center align-items-center border-radius-l bg-blue" style={{
                            width: 15,
                            height: 15,
                        }} /> : null}
                    </div>
                    <DKLine className="mt-m" />
                </div>
            );
        });

        if (this.state.appNameFilter === APP_NAME.ONEDRIVE && isEmpty(arr)) {
            return (
                <div className="column parent-width flex-1 justify-content-center align-items-center">
                    <OneDriveButton
                        onSuccess={(files: any) => this.onOneDriveFileSelection(files?.value)}
                        onCancel={() => {}}
                        onError={() => {}}
                    />
                </div>
            );
        }
        if (this.state.appNameFilter === APP_NAME.DROPBOX && isEmpty(arr)) {
            return (
                <div className="column parent-width flex-1 justify-content-center align-items-center">
                    <DropBoxButton
                        onSuccess={(files: any) => this.onDropBoxFileSelection(files)}
                        onCancel={() => {}}
                        onError={() => {}}
                    />
                </div>
            );
        }

        return arr;
    }
    getSelectedColumnsByTable = (tableName: string): string[] => {
        let columns: string[] = [];
        const table: IDataTable = this.state.selectedTables?.find((table: IDataTable) => table.name === tableName);
        if (table) {
            columns = table.columns;
        }
        return columns;
    };
    getSelectedCFColumnsByTable = (tableName: string): string[] => {
        let customFieldColumns: string[] = [];
        const table: IDataTable = this.state.selectedTables?.find((table: IDataTable) => table.name === tableName);
        if (table) {
            customFieldColumns = table.customFieldColumns;
        }
        return customFieldColumns;
    };
    renderColumnList() {
        const selectedColumns = this.getSelectedColumnsByTable(this.state.currentTable?.name);
        const selectedCustomColumns: any =this.getSelectedCFColumnsByTable(this.state.currentTable?.name);
        const columns = this.state.columns;

        let arr: any = [];
        columns?.forEach((column: any, index: number) => {
            arr.push(
                <div className="column parent-width p-h-r listPickerBG cursor-hand" onClick={() => this.onColumnSelect2(column)}>
                    <DKCheckMark
                        color="bg-button"
                        title={column?.display}
                        className="mt-r"
                        isSelected={(column.customField ? selectedCustomColumns : selectedColumns)?.includes(column.name)}
                    />
                    <DKLine className="mt-m" />
                </div>
            );
        });

        return isEmpty(arr) ? (
            <NoRecordFound
                top={"20%"}
                title={`No table selected`}
                subTitle={`Please select a data source table to proceed`}
            />
        ) : arr;
    }
    renderLoadIndicator = () => {
        return <div className="row parent-size align-items-center justify-content-center">
            <DKSpinner
                className="mr-xxl"
                iconClassName="ic-m "
                title="Loading table data..."
            />
        </div>
    }

    render() {
        return (
            <div className="transparent-background">
                <div
                    className="popup-window"
                    style={{
                        width: 600,
                        maxWidth: "90%",
                        padding: 0,
                        height: 650,
                        maxHeight: "90vh"
                    }}
                >
                    {this.getHeader()}
                    {!this.props.loading && <div className="column p-h-xl pt-xl pb-xxl parent-width flex-1">
                        {this.getGraphSelection()}
                        {this.getAppNameSegmentControl()}
                        {this.getTableSection()}
                    </div>}
                    {this.props.loading && this.renderLoadIndicator()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any, ownProps: any) => ({
    tables: state.table.reportsTables,
    loading: state.table.loading,
    tenantDetails: state.tenant.tenantInfo,
    permissions: state.tenant.permissions,
    appIntegrationMapping: state.appIntegration.connections,
    peopleEmployeeFields: state.records?.peopleEmployeeFields || [],
    peopleUsers: state.tenant.peopleUsers || [],
    hasMultiTenantAccess: state.tenant.multiTenantAccess
});
const mapDispatchToProps = {
    fetchReportTablesByAppName,
    getPeopleEmployeeFields,
    fetchMultiTenantAccess
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DataSourcePopup);
