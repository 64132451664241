import { FINANCIAL_REPORT } from './Enum';

export default class ApiConstants {
  static DEFAULT_PAGE_SIZE = 20;

  static BASE = process.env.REACT_APP_BASE_URL;
  static REPORT_BASE = 'reports-builder/';
  static PRODUCT_URL_GO = process.env.REACT_APP_URL_GO;

  static URL = {
    BILLING_URL: process.env.REACT_APP_BILLING_URL,
    USER_MANAGEMENT_URL: process.env.REACT_APP_USER_ROLES_URL,
    GO_UI_URL: process.env.REACT_APP_GO_UI_URL,

    FILE_UPLOAD: 'file/upload',
    ALL_UPLOADED_IMAGES: 'file/upload/list',
    SEND_EMAIL: 'anonymous/document-designer/email',
    IMPORT_EXPORT: {
      AUTOMATION_LOGS: `crm/core/workflow/automation-log`,
      EXPORT_AUTOMATION_LOGS: `crm/core/workflow/automation-log/export`,
      EXPORT_FILE: (tableId: any) => `crm/core/table/${tableId}/export`
    },
    TABLE: {
      GET_ALL: `/crm/core/table`,
      GET: (tableId: any) => `crm/core/table/${tableId}`,
      GET_RECORD_BY_PAGE: (tableId: any) =>
        `crm/core/table/${tableId}/paged-record`
    },
    /* Accounts */
    FETCH_ACCOUNTS: 'account',

    /* Taxes */
    REPORT_TAX_AGENCY: `tax-calc/tax-agency/codes`,

    /* Cash Flow */
    FINANCIAL_CASHFLOW_MAP: '/account/reports/cash-flow/mapping',
    UPDATE_MAPPING: '/account/reports/cash-flow/mapping',

    /* Report View */
    CUSTOMIZED_REPORT: `/reports/custom-view`,
    VIEW_OPTIONS: (type: FINANCIAL_REPORT) =>
      `/reports/view?financialReport=${type}`,
    DELETE_VIEW: (viewId: number) => `/reports/view/${viewId}`,
    CUSTOM_FIELDS_BY_MODULES: '/dimension/custom-fields-by-modules',

    /* Profit & Loss */
    PNL: '/reports/financial/pnl-v2',
    PNL_IN_BOTH_CURRENCY: '/reports/financial/pnl-v2-both-currency',
    EXPORT_PNL: `/reports/common/export-grouping-profit-and-loss`,
    REPORT_CASH_DETAILS: `/reports/financial/journal_entry`,

    /* Balance Sheet */
    BALANCE_SHEET: '/reports/financial/balance-sheet-v2',
    BALANCE_SHEET_IN_BOTH_CURRENCY:
      '/reports/financial/balance-sheet-v2-both-currency',
    EXPORT_BALANCE_SHEET: `/reports/common/export-grouping-balance-sheet-v2`,

    /* Trial Balance */
    TRIAL_BALANCE: '/reports/financial/trial-balance',
    TRIAL_BALANCE_DETAIL: '/reports/financial/grouping-trial-balance',
    EXPORT_TRIAL_BALANCE: `/reports/financial/trial-balance/export/excel`,

    SEND_EMAIL_TEMPLATE_REPORT: 'report-engine/email',

    PRODUCT: {
      FETCH_PRODUCTS: `/products`
    },

    CONTACTS: {
      FETCH_CONTACTS: `/contacts`
    },

    APP_INTEGRATION: {
      GET_AUTH_URL: 'crm/app-integration/onedrive/get-authorize-url',
      CONNECT: 'crm/app-integration/connect',
      DISCONNECT: 'crm/app-integration/disconnect',
      MAPPING: 'crm/app-integration/mapping',
      GET_ONE_DRIVE_FILES:
          'reports-builder/custom/get-file-list?appName=ONEDRIVE',
      SAVE_ONE_DRIVE_FILES: 'reports-builder/onedrive/save-file-meta',
      GET_DROP_BOX_FILES: 'reports-builder/custom/get-file-list?appName=DROPBOX',
      SAVE_DROP_BOX_FILES: 'reports-builder/dropbox/save-file-meta',
      UPDATE_ONE_DRIVE_FILES_COLUMN_MAPPING: (id: number) =>
        `reports-builder/custom/update-column-mapping/${id}`
    }
  };

  static AUTH = {
    LOGIN_REDIRECT: process.env.REACT_APP_LOGIN_URL,
    LOGIN_STATUS: 'iam/auth/sign-in/login/status',
    LOG_OUT: 'iam/auth/logout2',
    SWITCH_TENANT: `/iam/users/web/switch-tenant`
  };
  static SUBSCRIPTION = {
    SALES: `subengine/subscription/sales`
  };

  static TENANT = {
    GET_ALL: `users/tenants`,
    DETAILS: `tenants/details`,
    USERS: `crm/core/user/invite`,
    PERMISSIONS: `tenants/permissions`,
    FETCH_USER_INFO: `users/userinfo`,
    CRM_SETTING: `crm/core/settings/tenant`,
    GET_CURRENCY_ENUM: `multicurrency/getCurrencyEnum`,
    PEOPLE_USERS: `people/users`,
    MULTI_TENANT_ACCESS: (userId:any) => `users/user-with-role-across-orgs/${userId}`
  };

  static TABLE = {
    LIST: `${ApiConstants.REPORT_BASE}data-source/report-tables`
  };

  static REPORT = {
    CREATE: `${ApiConstants.REPORT_BASE}reports`,
    GET_ALL: `${ApiConstants.REPORT_BASE}reports/paged`,
    GET_BY_ID: (id: string) => `${ApiConstants.REPORT_BASE}reports/${id}`,
    UPDATE: (id: string) => `${ApiConstants.REPORT_BASE}reports/${id}`,
    EXECUTE: (v1: boolean) =>
      `${ApiConstants.REPORT_BASE}reports/execute${v1 ? '-v1' : ''}`,
    SEND_EMAIL: (v1: boolean) =>
      `${ApiConstants.REPORT_BASE}reports/email${v1 ? '-v1' : ''}`,
    EXPORT_DATA: (v1: boolean) =>
      `${ApiConstants.REPORT_BASE}reports/${v1 ? 'export-v1' : 'books/export'}`,
    EXPORT_FULL_REPORT: `reports-builder/reports/export-multiple-v1`,
    GET_ACTIVITIES_BY_CONTACT: `crm/core/report/activities-group-by-contact`,
    FILE_UPLOAD: `attachment/file-upload`,
    GET_TICKETS_BY_CONTACT: `crm/core/report/tickets-group-by-contact`
  };

  static AI_REPORT = {
    GET_DATA_BY_MSG_ID: (msgId:any,isMultiTenantData:boolean, isExport:boolean) => `pw-copilot-email-agent/thread/message/get-data-by-sql-query-id/${msgId}?isMultiTenantData=${isMultiTenantData}&isExport=${isExport}`,
    GET_DEFAULT_DATA:(isMultiTenantData:boolean, isExport:boolean)=> `pw-copilot-email-agent/thread/message/default-people-employee-data?isMultiTenantData=${isMultiTenantData}&isExport=${isExport}`,
    GET_DEFAULT_DATA_BY_CONTENT:(isMultiTenantData:boolean, isExport:boolean)=> `pw-copilot-email-agent/thread/message/save-sql-query?isMultiTenantData=${isMultiTenantData}&isExport=${isExport}`,
    EXPORT_BY_ID: (msgId:any)=> `pw-copilot-email-agent/thread/message/sql-query/export/${msgId}`,
    ROLE_FIELD_MAPPING_STATUS: 'pw-copilot-email-agent/thread/people-role-field-mapping/update'
  }

  static PEOPLE_FIELDS = {
    PEOPLE_EMPLOYEE_FIELD: ()=> `people/tenants/field-config`
  }
  static TEMPLATE = {
    DESKERA_URL:
      'https://www.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool',
    LANDING_PAGE_URL: '/'
  };

  static FILE_UPLOAD_BUCKETNAME = process.env.REACT_APP_FILE_UPLOAD_BUCKETNAME;
  static FILE_UPLOAD_RELATIVE_PATH = process.env.REACT_APP_FILE_UPLOAD_PATH;
  static URL_PRIVATE_EVENT_TRACKING =
    process.env.REACT_APP_URL_PRIVATE_EVENT_TRACKING;
}

export const COPILOT = {
  READ_CSV: 'pw-copilot-email-agent/read-csv'
}
